window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

const input = document.createElement('input');
input.type = 'hidden';
input.name = 'heyflowUUID';
input.id = 'heyflowUUID';
input.setAttribute('data-label', 'heyflowUUID');
input.value = crypto.randomUUID();
input.style = "display:none;"
console.log(input.value);
document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input);
});

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail.screenName);
});

window.addEventListener('heyflow-submit', (event) => {
    console.log('heyflow fields:', event);
});

// Adds a hidden field, that passes the UTM-parameters stored in a cookie.
document.addEventListener('DOMContentLoaded', function() {
    // Function to get cookie by name
    function getCookie(name) {
        let cookieArray = document.cookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookiePair = cookieArray[i].split('=');
            if (name == cookiePair[0].trim()) {
                return cookiePair[1];
            }
        }
        return null;
    }

    var cookieValue = getCookie('last_source');

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'e:information.UTM';
    input.id = 'e:information.UTM'; 
    input.setAttribute('data-label', 'e:information.UTM');
    input.value = cookieValue;
    input.style = "display:none;";

    // Append the input directly to the form
    const form = document.getElementsByTagName('form')[0];
    if (form) {
        form.appendChild(input);
        console.log('Hidden input added:', input);  // Log to confirm the input is added
    } else {
        console.log('Form not found, input not added');
    }
});
